import React from "react";
import {Box, darken, Popover} from "@mui/material";
import {ChromePicker} from "react-color";
import DialogContent from "@mui/material/DialogContent";
import {Palette} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";

export const CustomColorPicker = ({color, handleUpdateColor}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [open, setOpen] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const updateColor = (event) => {
        console.log("updateColor")
        handleUpdateColor(event?.hex)
    }

    return (
        <Box>
            <Box onClick={handleClick}
                 sx={{position:'relative', width: '2.5rem', height: '2.5rem', background: color || customTheme.palette.primary.main, borderRadius:'0.4rem',
                     border: '2px solid ' + darken(color || customTheme.palette.primary.main, 0.2),}}
                 display={'flex'}>
                <Palette sx={{
                    margin: 'auto',
                    color: customTheme.palette.getContrastText(color || customTheme.palette.primary.main)
                }}
                         fontSize={'small'}/>
            </Box>
            <Popover id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
            >
                <DialogContent>
                    <ChromePicker color={color || customTheme.palette.primary.main} onChange={updateColor}/>
                </DialogContent>
            </Popover>
        </Box>
    )
}
