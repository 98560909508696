import React from "react";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {find, orderBy} from "lodash";

export const forms = {
    CHECKIN: {
        id: "checkin",
        label: "Check-in",
        position: 100,
        timelineField: 'checkedInAt',
        precisionMinute: true,
        description: 'Struttura che consente di monitorare l\'accesso all\'evento',
        sections: [
            {
                "editable": false,
                "label": "Check-In",
                "mainSection": true,
                fields: [
                    {
                        "defaultVisible": true,
                        "id": "checkedInAt",
                        "label": "Orario di accesso",
                        "type": "datetime"
                    },
                    {
                        "defaultVisible": true,
                        "id": "checkin",
                        "label": "Accesso",
                        "type": "checkbox"
                    },
                ]
            }
        ]
    },
    ARCHIVED: {
        id: "archived",
        label: "",
        position: 101,
        timelineField: 'updatedAt',
        precisionMinute: false,
        description: 'Utenti archiviati'
    },
}

const settingsUrl = () => {
    return `${API_URL}config/settings`
}

const getSettings = () => {
    return axios.get(settingsUrl(), {headers: authHeader()})
};

const saveSettings = (settings) => {
    return axios.post(settingsUrl(), {...settings}, {headers: authHeader()})
};

const getForms = (settings, dataStructures) => {
    if(!settings?.checkin) {
        delete dataStructures[forms.CHECKIN.id]
    } else {
        dataStructures[forms.CHECKIN.id] = {
            ...forms.CHECKIN,
            sections: [...find(dataStructures, ['main', true]).sections, ...forms.CHECKIN.sections],
            position: Object.values(dataStructures).length
        }
    }

    return orderBy(Object.values(dataStructures), 'position')
};

const SettingsService = {
    settingsUrl,
    getSettings,
    saveSettings,
    getForms
};

export default SettingsService;